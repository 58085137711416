import $ from "jquery";
import {isBlank} from "../../common-string.js";
import {parseBoolean} from "../../common-type.js";
import {initializeZipcodeFields} from "./fields/zipcode-input.js";
import {initializeTelephoneFields} from "./fields/phone-input.js";
import {initializeEmailFields} from "./fields/email-input.js";
import {initializeStreetAddressFields} from "./fields/street-address-input.js";
import {initializeNameFields} from "./fields/name-input.js";
import {initializeDateOfBirthFields} from "./fields/date-of-birth-input.js";
import {initializeDateOfBirthSelectors} from "./fields/date-of-birth-select.js";
import {initializeInsuranceTypeFields} from "./fields/insurance-type.js";
import "./fields/gender-buttons.js";

$("form").each(function () {
    const form = $(this);
    form.addClass("needs-validation");
    form.attr("novalidate", "true");
    form.on("submit", function (event) {
        form.removeClass("was-validated");
        if (!form.get(0).checkValidity()) {
            event.preventDefault();
            event.stopPropagation();
        }
        form.addClass("was-validated");
    });
});

$("label").each(function () {
    $(this).addClass("form-label");
});

$("select").each(function () {
    $(this).addClass("form-select");
});

$("input[type=text],input[type=tel],input[type=email]").each(function () {
    $(this).addClass("form-control");
});
$("input[type=checkbox]").each(function () {
    $(this).addClass("form-check-input");
});
$("textarea").each(function () {
    $(this).addClass("form-control");
});

/**
 * Validate a field based on the input field attributes.
 *
 * @param {any|jQuery|HTMLElement} field the field to validate
 * @returns {boolean} true, if meets field attribute requirements
 */
export function validateField(field) {
    let valid = true;
    if (field.length > 0) {
        /* There are situations where we have a generic field handler that
         * may be invoked even when a field is not present on a specific page
         * (for example, insuranceType and gender). */
        const value = field.val();
        if (isBlank(value)) {
            valid = !parseBoolean(field.data("val-required"));
        } else {
            valid = true;
            const regex = field.prop("pattern");
            if (regex) {
                valid = valid && value.match(regex) != null;
            }
            const maxlength = field.prop("maxlength");
            if (maxlength > 0) {
                valid = valid && value.length <= maxlength;
            }
            const minlength = field.prop("minlength");
            if (minlength > 0) {
                valid = valid && value.length >= minlength;
            }
        }
    }

    return valid;
}

window.validateField = validateField;

/**
 * Show or hide errors based on validation results.
 *
 * @param {any|jQuery|HTMLElement} inputField input field element
 * @param {any|jQuery|HTMLElement} errorMessageElement the error message element
 * @param {boolean} isValid the validation results
 */
export function handleValidation(inputField, errorMessageElement, isValid = true) {
    isValid = isValid && validateField(inputField) && !inputField.hasClass("error");
    if (isValid) {
        inputField.addClass("is-valid").removeClass("is-invalid");
        errorMessageElement.hide();
    } else {
        inputField.removeClass("is-valid").addClass("is-invalid");
        errorMessageElement.show();
    }

    return isValid;
}

window.handleValidation = handleValidation;

/**
 *
 * @param {any|jQuery|HTMLElement} fieldElement
 * @param {string} value
 */
export function setFormField(fieldElement, value) {
    if (fieldElement.is("input")) {
        if (fieldElement.prop("type") === "checkbox" || fieldElement.prop("type") === "radio") {
            fieldElement.prop("checked", parseBoolean(value));
        } else {
            fieldElement.val(value);
        }
    } else if (fieldElement.is("select")) {
        fieldElement.find("option:selected").prop("selected", false);
        fieldElement.find("option[value='" + value + "']").prop("selected", true);
        if (!fieldElement.find("option:selected").length) {
            fieldElement.find("option[text='" + value + "']").prop("selected", true);
        }
    } else {
        fieldElement.val(value);
    }
}

window.setFormField = setFormField;

/**
 * Wait for an element.
 *
 * @param {string} selector the CSS selector
 * @param {function} callback the function to call when found
 * @param {number} checkFrequencyInMs check interval in milliseconds
 * @param {number} timeoutInMs total milliseconds allowed
 */
export function waitForNotElement(selector, callback, checkFrequencyInMs = 200, timeoutInMs = 10000) {
    const startTimeInMs = Date.now();
    (function loopSearch() {
        if ($(selector).length === 0) {
            callback();
        } else {
            setTimeout(
                function () {
                    if (timeoutInMs && Date.now() - startTimeInMs > timeoutInMs) {
                        callback();
                    }
                    loopSearch();
                }
                , checkFrequencyInMs,
            );
        }
    })();
}

$("form input").on("keyup keypress", function (e) {
    return e.which !== 13;
});

initializeZipcodeFields();
initializeTelephoneFields();
initializeEmailFields();
initializeStreetAddressFields();
initializeNameFields();
initializeDateOfBirthFields();
initializeDateOfBirthSelectors();
initializeInsuranceTypeFields();
