import $ from "jquery";
import {handleValidation, validateField} from "../form.js";
import {insertHiddenField, removeHiddenGroupFields} from "./hidden-fields.js";
import {parseBoolean, sanitizePhone} from "../../../common-type.js";

/** Initialize telephone fields. */
export function initializeTelephoneFields() {
    $("input[name=phone]").each(function () {
        const phoneField = $(this);
        const placeholder = phoneField.prop("placeholder") || "(000) 000-0000";
        phoneField
            .data("val-required", true)
            .mask("(000) 000-0000")
            .prop("pattern", "^\\([0-9]{3}\\) [0-9]{3}-[0-9]{4}$")
            .prop("maxlength", "14")
            .prop("placeholder", placeholder)
            .prop("aria-label", "Your telephone number")
            .prop("autocomplete", "tel");

        const id = phoneField.prop("id");
        const errorElementId = id + "-error";
        let errorElement = $("#" + errorElementId);

        if (errorElement.length === 0) {
            errorElement = $("<div>", {
                "id": errorElementId,
                "data-selector": "phone-error",
                class: "invalid-feedback text-end",
                html: "Please provide a valid phone number",
            }).insertAfter(phoneField);
        }

        const verifiedPhoneField = insertHiddenField(phoneField, "verifiedPhone", "false", "phone-hidden");
        const phoneVerifiedField = insertHiddenField(phoneField, "phoneVerified", "", "phone-hidden");

        phoneField.on("change", function () {
            if (!parseBoolean(verifiedPhoneField.val()) || phoneField.val() !== phoneVerifiedField.val()) {
                verifiedPhoneField.val("false");
                verifyTelephone(phoneField, errorElement);
            }
        });

        phoneField.on("keypress", function () {
            phoneField.removeClass("is-valid").removeClass("is-invalid");
        });

        if (validateField(phoneField)) {
            phoneField.trigger("change");
        }

        phoneField.on("paste", function (event) {
            event.stopPropagation();
            const phoneField = $(this);
            navigator.clipboard.readText().then(clipboardData => {
                const data = clipboardData
                    .replace(/\D*/, "")
                    .replace(/^1*/, "");
                phoneField.val(data);
                phoneField.mask("(000) 000-0000");
            }).catch(() => {
                /* Do nothing */
            });
        });
    });
}

/**
 * Validate the phone number format and verify the phone number with a third-party provider.
 *
 * @param {any|jQuery|HTMLElement} phoneField the phone number field
 * @param {any|jQuery|HTMLElement} phoneError the invalid phone error message field
 *
 * @returns {boolean} true if the phone number is verified, otherwise false
 */
function verifyTelephone(phoneField, phoneError) {
    phoneField.addClass("verifying").removeClass("is-valid").removeClass("is-invalid");
    removeHiddenGroupFields(phoneField, "phone-hidden");

    const phoneNumber = sanitizePhone(phoneField.val());
    if (!phoneNumber) {
        phoneField.removeClass("verifying");
        return handleValidation(phoneField, phoneError, false);
    } else if (!handleValidation(phoneField, phoneError)) {
        phoneField.removeClass("verifying");
        return false;
    }

    const csrfToken = $("meta[name=\"csrf-token\"]").attr("content");

    /**
     * Verify the phone number with a third-party provider.
     *
     * @typedef {object} TelephoneVerificationResponse The phone validation API response object
     * @property {string} data.phone the phone number
     * @property {string} data.phoneDigits the phone number in digits
     * @property {string} data.international the international format of the phone number
     * @property {string} data.phoneType the phone type (mobile, landline)
     * @property {boolean} data.validPhone whether the phone number is valid
     * @property {boolean} data.verifiedPhone the verified phone number flag
     */
    $.post({
        url: "/api/telephone/verify",
        data: {phone: phoneNumber},
        headers: {
            "X-CSRF-TOKEN": csrfToken,
        },
        cache: true,
        dataType: "json",
        success:
            /** @param {TelephoneVerificationResponse} response The telephone validation API response */
            function (response) {
                const verifiedPhone = parseBoolean(response.data.verifiedPhone);
                if (verifiedPhone) {
                    addPhoneHiddenFields(phoneField, response);
                }
                handleValidation(phoneField, phoneError, verifiedPhone);
            },
        error: function (xhr) {
            handleValidation(phoneField, phoneError, xhr.status > 499);
        },
        complete: function () {
            phoneField.removeClass("verifying");
        },
    });
}

/**
 * Add hidden input fields for phone details.
 *
 * @param {any|jQuery|HTMLElement} phoneField the phone input field
 * @param {TelephoneVerificationResponse} response the response object containing the data
 */
function addPhoneHiddenFields(phoneField, response) {
    const fields = {
        phoneVerified: phoneField.val(),
        phoneDigits: response.data.phoneDigits,
        international: response.data.international,
        phoneType: response.data.phoneType || "unknown",
        validPhone: response.data.validPhone.toString(),
        verifiedPhone: response.data.verifiedPhone.toString(),
    };

    for (const [name, value] of Object.entries(fields)) {
        insertHiddenField(phoneField, name, value, "phone-hidden");
    }
}
