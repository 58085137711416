import $ from "jquery";
import {handleValidation, validateField} from "../form.js";
import {insertHiddenField, removeHiddenGroupFields} from "./hidden-fields.js";
import {parseBoolean} from "../../../common-type.js";

/** Initialize email fields. */
export function initializeEmailFields() {
    $("input[name=email]").each(function () {
        const emailField = $(this);
        const placeholder = emailField.prop("placeholder") || "name@example.com";
        emailField
            .data("val-required", true)
            .prop("minlength", "10")
            .prop("maxlength", "100")
            .prop("placeholder", placeholder)
            .prop("aria-label", "Your e-mail address")
            .prop("autocomplete", "email")
            .prop("type", "email");

        const id = emailField.prop("id");
        const errorElementId = id + "-error";
        let errorElement = $("#" + errorElementId);

        if (errorElement.length === 0) {
            errorElement = $("<div>", {
                "id": errorElementId,
                "data-selector": errorElementId,
                class: "invalid-feedback text-end",
                html: "Please provide a valid email address",
            }).insertAfter(emailField);
        }

        const verifiedEmailField = insertHiddenField(emailField, "verifiedEmail", "false", "email-hidden");
        const emailVerifiedField = insertHiddenField(emailField, "emailVerified", "", "email-hidden");

        emailField.on("change", function () {
            if (!parseBoolean(verifiedEmailField.val()) || emailField.val() !== emailVerifiedField.val()) {
                verifiedEmailField.val("false");
                verifyEmailAddress(emailField, errorElement);
            }
        });

        emailField.on("keypress", function () {
            emailField.removeClass("is-valid").removeClass("is-invalid");
        });

        if (validateField(emailField)) {
            emailField.trigger("change");
        }
    });
}

/**
 * Validate the email address format and verify the email address with a third-party provider.
 *
 * @param {any|jQuery|HTMLElement} emailField the email address field
 * @param {any|jQuery|HTMLElement} emailError the invalid email address error message field
 *
 * @returns {boolean} true if the email address is verified, otherwise false
 */
export function verifyEmailAddress(emailField, emailError) {
    emailField.addClass("verifying").removeClass("is-valid").removeClass("is-invalid");
    removeHiddenGroupFields(emailField, "email-hidden");

    const emailAddress = emailField.val();
    if (!emailAddress) {
        emailField.removeClass("verifying");
        return handleValidation(emailField, emailError, false);
    } else if (!handleValidation(emailField, emailError)) {
        emailField.removeClass("verifying");
        return false;
    }

    const csrfToken = $("meta[name=\"csrf-token\"]").attr("content");

    /**
     * Verify the email address with a third-party provider.
     *
     * @typedef {object} EmailVerificationResponse the email validation API response object
     * @property {string} email the email address
     * @property {string} validEmail whether the email is valid ("true"/"false")
     * @property {string} verifiedEmail whether the email is verified ("true"/"false")
     */
    $.post({
        url: "/api/emails/verify",
        data: {email: emailAddress},
        headers: {
            "X-CSRF-TOKEN": csrfToken,
        },
        cache: true,
        dataType: "json",
        success:
            /** @param {EmailVerificationResponse} response The email validation API response */
            function (response) {
                const validEmail = parseBoolean(response.validEmail);
                const verifiedEmail = parseBoolean(response.verifiedEmail);

                if (verifiedEmail) {
                    addEmailHiddenFields(emailField, response);
                    $("input[name=email]").each(function () {
                        $(this).val(response.email);
                    });
                    handleValidation(emailField, emailError, verifiedEmail);
                } else if (!validEmail) {
                    handleValidation(emailField, emailError, validEmail);
                }
            },
        error: function (xhr) {
            handleValidation(emailField, emailError, xhr.status > 499);
        },
        complete: function () {
            emailField.removeClass("verifying");
        },
    });
}

/**
 * Add hidden input fields for email details.
 *
 * @param {any|jQuery|HTMLElement} emailField the email input field
 * @param {EmailVerificationResponse} response the response object containing the data
 */
function addEmailHiddenFields(emailField, response) {
    const fields = {
        emailVerified: emailField.val(),
        validEmail: response.validEmail,
        verifiedEmail: response.verifiedEmail,
    };

    for (const [name, value] of Object.entries(fields)) {
        insertHiddenField(emailField, name, value, "email-hidden");
    }
}
