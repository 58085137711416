import $ from "jquery";
import {handleValidation} from "../form.js";

const gender = $("#gender-container");
const genderError = $("#gender-error");
const genderFemaleLabel = $("#gender-female-button");
const genderMaleLabel = $("#gender-male-button");

genderFemaleLabel.on("click", function () {
    handleValidation(gender, genderError, true);
    $("#gender-male-button").removeClass("is-invalid");
    $("#gender-female-button").removeClass("is-invalid");
});

genderMaleLabel.on("click", function () {
    handleValidation(gender, genderError, true);
    $("#gender-male-button").removeClass("is-invalid");
    $("#gender-female-button").removeClass("is-invalid");
});
