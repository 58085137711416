import $ from "jquery";
import {isNotBlank} from "../../../common-string.js";
import {arrayIncludes} from "../../../common-type.js";
import {handleValidation} from "../form.js";
import {whichForm} from "../healthquotes.js";

/** Initialize insurance type fields. */
export function initializeInsuranceTypeFields() {
    $("input[name=insuranceType],select[name=insuranceType]").each(function () {
        const insuranceTypeField = $(this)
            .data("val-required", true)
            .prop("aria-label", "Insurance Plan Type")
            .change();
        const insuranceTypeError = $("<div>", {
            "data-selector": "insuranceType-error",
            class: "invalid-feedback text-end",
            html: "Please select an insurance type",
        }).insertAfter(insuranceTypeField);

        insuranceTypeField.on("change", function () {
            handleValidation(insuranceTypeField, insuranceTypeError);
            whichForm();
        });
    });

    const dobField = $("#dob");
    if (dobField.val()) {
        dobField.trigger("change");
    }
}

/**
 * Validate the selected insurance type.
 *
 * @param {string} insuranceType the insurance type requested
 * @returns {boolean} true, if valid insurance type requested
 */
export function validateInsuranceType(insuranceType) {
    const insuranceTypes = ["Health Insurance", "Short Term Health Plan", "Medicare Supplement", "Medicare Advantage"];

    return isNotBlank(insuranceType) && arrayIncludes(insuranceTypes, insuranceType);
}
