import $ from "jquery";
import {isNotBlank} from "../../common-string.js";
import {sanitizePhone} from "../../common-type.js";
import moment from "moment";
import {handleValidation} from "./form.js";

/**
 * Validate a phone number.
 * @param phoneNumber the phone number to test
 * @returns true if is valid
 * @see sanitizePhone
 */
export function validatePhone(phoneNumber) {
    return sanitizePhone(phoneNumber) > "";
}

function calculateAge(birthDate) {
    const now = moment();
    const age = now.diff(birthDate, "years", true);
    return age > 0 ? age : 0;
}

export function validateDateOfBirth(dob) {
    const birthDate = moment(dob, "MM/DD/YYYY");
    return isNotBlank(dob) && birthDate.isValid() ? calculateAge(birthDate) : 0;
}

export function validateDateOfBirthField(dob, dobError) {
    let age = validateDateOfBirth(dob.val());
    if (age) {
        handleValidation(dob, dobError, true);
        if (age < 18) {
            dobError.text("Primary applicant must be age 18 or older");
            handleValidation(dob, dobError, false);
            age = 0;
        } else if (age > 100) {
            dobError.text("Primary applicant must be under 100");
            handleValidation(dob, dobError, false);
            age = 0;
        }
    } else {
        dobError.text("Please enter a valid date of birth");
        handleValidation(dob, dobError, false);
        age = 0;
    }

    return age;
}

window.validateDateOfBirthField = validateDateOfBirthField;

export function validateDateOfBirthFields(dobYear, dobMonth, dobDay, dob, dobError) {
    dob.val("");
    const complete = dobDay.val() && dobYear.val() && !dobDay.hasClass("is-invalid") && !dobYear.hasClass("is-invalid");
    if (complete) {
        dob.val((dobMonth.val().length === 1 ? "0" : "") + dobMonth.val() + "/" + (dobDay.val().length === 1 ? "0" : "") + dobDay.val() + "/" + dobYear.val());
        const age = validateDateOfBirthField(dob, dobError);
        if (age) {
            dobYear.addClass("is-valid").removeClass("is-invalid");
            dobMonth.addClass("is-valid").removeClass("is-invalid");
            dobDay.addClass("is-valid").removeClass("is-invalid");
            dob.trigger("change");
        } else {
            dobYear.removeClass("is-valid").addClass("is-invalid");
            dobMonth.removeClass("is-valid").addClass("is-invalid");
            dobDay.removeClass("is-valid").addClass("is-invalid");
            dobError.show();
        }
    }
}

export function validateGenderButtons(genderMaleButton, genderFemaleButton, genderError) {
    var genderMaleField = $("#" + genderMaleButton.prop("for"));
    var genderFemaleField = $("#" + genderFemaleButton.prop("for"));
    var isValid = genderMaleField.is(":checked") || genderFemaleField.is(":checked");

    handleValidation(genderMaleButton, genderError, isValid);
    return handleValidation(genderFemaleButton, genderError, isValid);
}
